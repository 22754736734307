import { Flex, Li, List } from '../../components';
import { Settings_navigation } from '../../core-types';
import { ChangeLocaleLink } from './ChangeLocaleLink';
import { LogInOrProfile } from './LogInOrProfile';
import { NavLink } from './NavLink';
import { NavigationLink } from './NavigationLink';

export const Navigation = ({
  items,
  enableLogin,
  isLoggedIn
}: {
  items: Settings_navigation[];
  enableLogin?: boolean;
  isLoggedIn: boolean;
}) => {
  return (
    <List
      display="flex"
      flexDirection={{ _: 'column', xl: 'row' }}
      alignItems={{ _: 'flex-start', xs: 'flex-end', xl: 'center' }}
      data-testid="Navigation"
    >
      {items.map(({ _key, page, title, externalLink }, i) => {
        return (
          <Li key={_key || i}>
            {page && (
              <NavigationLink page={page}>
                <NavLink>{title}</NavLink>
              </NavigationLink>
            )}
            {!page && externalLink && (
              <NavLink href={externalLink}>{title}</NavLink>
            )}
          </Li>
        );
      })}
      <ChangeLocaleLink messageLength="short" selectMode={true}>
        {({ anchorProps }) => {
          return (
            <NavLink href={anchorProps.href}>
              <Flex alignItems="center" justifyContent="flex-end">
                {anchorProps.children}
              </Flex>
            </NavLink>
          );
        }}
      </ChangeLocaleLink>
      {enableLogin && <LogInOrProfile isLoggedIn={isLoggedIn} />}
    </List>
  );
};
