import { themeColors } from '@urbaninfrastructure/design-tokens';
import {
  Mobile as MobileIcon,
  Right as RightIcon
} from '@urbaninfrastructure/react-icons';
import { AnchorProps } from '@urbaninfrastructure/react-ui-kit';
import { forwardRef, useRef } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useIntersection } from 'use-intersection';
import {
  Anchor,
  AnchorButton,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Li,
  Link,
  List,
  SanityLink,
  SocialIcon,
  SocialIconAnchor,
  Text
} from '../../components';
import { useAppLinkProps } from '../../components/AppLink';
import { BlockContent } from '../../components/BlockContent';
import {
  I18NLogOut,
  I18NProfile,
  I18NReceipts
  //I18NPinCode
} from '../../components/common-i18n';
import {
  CurrentUser,
  FooterNavColumn,
  Settings_footer,
  SocialMediaName,
  settings_system
} from '../../core-types';
import { useSanityQuery } from '../../lib/core/useSanityQuery';
import { useStyledTheme } from '../../lib/hooks';
import { globalMessages } from '../../lib/messages';
import { Router } from '../../lib/routes';
import {
  isBikeMi,
  isInurbaRouen,
  isInurbaGdansk,
  isLondonGreenquarter
} from '../../lib/util';
import { useAuth } from '../../lib/with-auth';
import Container from '../HowItWorks/Container';
import { ChangeLocaleLink } from './ChangeLocaleLink';
import IntercomSearchForm from './IntercomSearchForm';
//import { useStaticDataSystem } from '../../lib/core/staticDataQuery';
import { useSystemConfig } from '../../lib/system-config-context';
import { dinamicRenderingConfig } from '../../lib/server/middlewares/system-config/dinamic-rendering';
const sponsorsPath = '../../static/assets/sponsors';

type Props = {
  footer: Settings_footer | null;
  system: settings_system | null;
  currentUser?: CurrentUser | null;
  enableLogin?: boolean;
};

const messages = defineMessages({
  profileMenuTitle: {
    id: 'components.Layout.Footer.profileMenuTitle',
    defaultMessage: 'You'
  },
  helpMenuTitle: {
    id: 'components.Layout.Footer.helpMenuTitle',
    defaultMessage: 'Help'
  },
  contactUsBy: {
    id: 'components.Layout.Footer.contactUsBy',
    defaultMessage: 'Contact us by chat, phone {phoneLink} or email {emailLink}'
  },
  searchHelpTopics: {
    id: 'components.Layout.Footer.searchHelpTopics',
    defaultMessage: 'Search help topics'
  },
  citybikePartner: {
    id: 'components.Layout.Footer.citybikePartner',
    defaultMessage: '{sponsorName} City Bike Partner'
  }
});

export function useColors() {
  const theme = useStyledTheme();
  const systemConfig = useSystemConfig();
  const bikeMi = isBikeMi(theme);
  const inurbaRouen = isInurbaRouen(systemConfig);
  const inurbaGdansk = isInurbaGdansk(systemConfig);
  const londonGreenQuarter = isLondonGreenquarter(systemConfig);

  let color = 'white';
  let mutedColor = 'neutral.4';
  let backgroundColor = 'text';

  if (bikeMi) {
    backgroundColor = 'primaryDark';
  } else if (inurbaRouen) {
    backgroundColor = themeColors.purple03.primary;
  } else if (inurbaGdansk || londonGreenQuarter) {
    backgroundColor = 'black';
  }

  return {
    color,
    mutedColor,
    backgroundColor
  };
}

const FooterAnchor = forwardRef<
  HTMLAnchorElement,
  Omit<React.HTMLProps<HTMLAnchorElement>, 'ref' | 'color'> &
    AnchorProps & { children: React.ReactNode }
>(function FooterAnchor(props, ref) {
  const { color } = useColors();
  return <Anchor ref={ref} {...props} color={color} />;
});

const FooterGrid = styled(Box)`
  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints[0]}) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -${theme.space[5]}px;
      }
    `;
  }};
`;

const FooterCell = styled(Box)`
  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints[0]}) {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 ${theme.space[5]}px;
      }
      @media screen and (min-width: ${theme.breakpoints[3]}) {
        flex-basis: 33%;
        max-width: 33%;
      }
    `;
  }};
`;
FooterCell.defaultProps = {
  mb: 'md'
};

const StyledCallOutAnchor = styled(Box)`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${theme.colors.neutral[1]};
      transition: background-color 100ms ease-out;
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.neutral[2]};
      }
    `;
  }};
`;

interface SanityFooterQuery {
  footer?: {
    logoWall?: {
      _key: string;
      alt?: string;
      url?: string;
      blank?: boolean;
      logo?: {
        url: string;
        metadata: {
          dimensions: {
            aspectRatio: number;
          };
        };
      };
    }[];
    socialMediaCallout: {
      icon: SocialMediaName | null;
      text: string | null;
      url: string | null;
      visible: boolean | null;
    } | null;
  };
}

const useSanityFooterQuery = (): SanityFooterQuery['footer'] => {
  const { data } = useSanityQuery<SanityFooterQuery>({
    ssr: false,
    variables: {
      query: `// groq
      *[_id == "global-settings"][0] {
        footer {
          socialMediaCallout,
          logoWall[] {
            _key,
            url,
            blank,
            "alt": logo.alt,
            "logo": logo.asset->{
              url
            }
          }
        }
      }
    `
    }
  });
  return data && data.footer;
};

const CallOutBox = () => {
  const footer = useSanityFooterQuery();
  if (!footer || !footer.socialMediaCallout) {
    return null;
  }
  const { icon, url, text, visible } = footer.socialMediaCallout;
  if (!visible || !text || !url) {
    return null;
  }
  return (
    <StyledCallOutAnchor
      as="a"
      mb="md"
      href={url}
      p="xs"
      borderRadius="lg"
      boxShadow="heavy"
    >
      <Flex alignItems="center">
        {icon && <SocialIcon name={icon} color="text" />}
        <Text color="text" small pl="xxs">
          {text}
        </Text>
      </Flex>
      <RightIcon size="24px" color="text" ml="xxs" />
    </StyledCallOutAnchor>
  );
};

function SectionHeader(props) {
  return <Text small mb="xs" as="h2" fontWeight="normal" {...props} />;
}

const MenuList = ({
  navColumn,
  large,
  linkColor
}: {
  navColumn: FooterNavColumn;
  large?: boolean;
  linkColor: string;
}) => {
  if (!navColumn || !navColumn.items || navColumn.items.length === 0) {
    return null;
  }
  const { items } = navColumn;

  return (
    <FooterCell as="section">
      <SectionHeader>{navColumn.title}</SectionHeader>
      <List>
        {items.map((item, i) => {
          if (!item || !item.title) {
            return null;
          }

          return (
            <Li key={item._key || i} mb={large ? 'xxs' : [2, 2, 2, 2, 0]}>
              <SanityLink
                link={{
                  _key: item._key,
                  page: item.page,
                  externalLink: item.externalLink,
                  showIntercom: item.showIntercom
                }}
              >
                {anchorProps => {
                  const typoStyle = large ? 'sm' : 'xs';
                  const textDecoration = 'none';
                  if (anchorProps.onClick) {
                    return (
                      <AnchorButton
                        textDecoration={textDecoration}
                        typoStyle={typoStyle}
                        color={linkColor}
                        onClick={anchorProps.onClick}
                      >
                        {item.title}
                      </AnchorButton>
                    );
                  }
                  return (
                    <FooterAnchor
                      textDecoration={textDecoration}
                      typoStyle={typoStyle}
                      href={anchorProps.href}
                    >
                      {item.title}
                    </FooterAnchor>
                  );
                }}
              </SanityLink>
            </Li>
          );
        })}
      </List>
    </FooterCell>
  );
};

const LogoWall = () => {
  const footer = useSanityFooterQuery();

  if (!footer || !footer.logoWall || footer.logoWall.length === 0) {
    return null;
  }

  const maxWidth = 150;

  return (
    <Box
      color="text"
      backgroundColor="white"
      borderRadius="lg"
      boxShadow="heavy"
      p="xs"
      mb="md"
    >
      <Flex
        flexWrap="wrap"
        flexDirection={{ _: 'column', sm: 'row' }}
        justifyContent="space-around"
        alignItems="center"
      >
        {footer.logoWall.map(item => {
          if (!item.logo) {
            return null;
          }
          return (
            <Anchor
              key={item._key}
              href={item.url}
              target={item.blank ? 'blank' : undefined}
              display="block"
              mb={{ _: 3, sm: 0 }}
              style={{ maxWidth }}
            >
              <Image
                src={`${item.logo.url}?w=${maxWidth}&fit=min&fm=png&dpr=2`}
                alt={item.alt}
                loading="lazy"
              />
            </Anchor>
          );
        })}
      </Flex>
    </Box>
  );
};

const Footer = ({ footer, system, currentUser, enableLogin }: Props) => {
  const { logOut } = useAuth();
  const appLinkProps = useAppLinkProps();
  //const staticDataSystem = useStaticDataSystem();
  const intl = useIntl();
  const ref = useRef<HTMLElement | null>(null);
  const intersected = useIntersection(ref, { once: true });
  const { color, backgroundColor, mutedColor } = useColors();
  const showFooter = dinamicRenderingConfig.find(
    config => config.systemId === system?.id && config.showFooter
  );
  const isLondonGreenquarter = system?.id === 'london-greenquarter';

  if (!footer || !system || !showFooter) {
    return null;
  }

  const { locale } = intl;
  const { sponsor, intercomHelpSite } = system;
  const { socialMediaLinks, aboutOperator, helpText } = footer;

  const navColumns = footer.navColumns || [];

  return (
    <Box
      ref={ref}
      as="footer"
      data-testid="Footer"
      py={[5, 5, 7]}
      flex="none"
      bg={backgroundColor}
      color={color}
    >
      <Container maxWidth="md">
        {intersected && (
          <>
            <LogoWall />
            <CallOutBox />
          </>
        )}
        <FooterGrid as="nav">
          {navColumns[0] && (
            <MenuList navColumn={navColumns[0]} large linkColor={color} />
          )}

          {navColumns[1] && (
            <MenuList navColumn={navColumns[1]} large linkColor={color} />
          )}

          <FooterCell as="section" fontSize={[2, 2, 2, 3]}>
            <SectionHeader>
              <FormattedMessage {...messages.profileMenuTitle} />
            </SectionHeader>
            <List>
              {enableLogin && !currentUser && (
                <Li mb="xxs" key="profile">
                  <Link route="log-in" passHref>
                    <FooterAnchor textDecoration="none">
                      <FormattedMessage {...globalMessages.logIn} />
                    </FooterAnchor>
                  </Link>
                </Li>
              )}
              {currentUser && (
                <>
                  <Li mb="xxs" key="profile">
                    <Link route="profile" passHref>
                      <FooterAnchor textDecoration="none">
                        <I18NProfile />
                      </FooterAnchor>
                    </Link>
                  </Li>
                  <Li mb="xxs" key="receipts">
                    <Link route="profile.receipts" passHref>
                      <FooterAnchor
                        fontSize={[2, 2, 2, 3]}
                        textDecoration="none"
                      >
                        <I18NReceipts />
                      </FooterAnchor>
                    </Link>
                  </Li>
                  {/** We do not have systems which support PIN code functionality currently.*/}
                  {/* {staticDataSystem &&
                    staticDataSystem.usersCanUnlockWithPinCode && (
                      <Li mb="xxs" key="pin">
                        <Link route="profile.pin-code" passHref>
                          <FooterAnchor
                            fontSize={[2, 2, 2, 3]}
                            textDecoration="none"
                          >
                            <I18NPinCode />
                          </FooterAnchor>
                        </Link>
                      </Li>
                    )} */}
                </>
              )}
              <Li mb="xxs" key="language">
                <ChangeLocaleLink messageLength="long" selectMode={false}>
                  {({ anchorProps }) => {
                    return (
                      <FooterAnchor
                        {...anchorProps}
                        fontSize={[2, 2, 2, 3]}
                        textDecoration="none"
                      />
                    );
                  }}
                </ChangeLocaleLink>
              </Li>
              {currentUser && (
                <Li mb="xxs" key="logout">
                  <Button
                    small
                    shape="cool"
                    variant="primary"
                    onClick={async () => {
                      await logOut();
                      // redirect home after logOut
                      Router.pushRoute('home');
                    }}
                  >
                    <I18NLogOut />
                  </Button>
                </Li>
              )}
            </List>
          </FooterCell>
        </FooterGrid>

        <FooterGrid>
          <FooterCell as="section">
            <SectionHeader>
              {intercomHelpSite ? (
                <Anchor color="white" href={intercomHelpSite}>
                  <FormattedMessage {...messages.helpMenuTitle} />
                </Anchor>
              ) : (
                <FormattedMessage {...messages.helpMenuTitle} />
              )}
            </SectionHeader>
            {intercomHelpSite && (
              <IntercomSearchForm
                intercomHelpSite={intercomHelpSite}
                placeholder={intl.formatMessage(messages.searchHelpTopics)}
              />
            )}
            {system.parsedContactNumber && (
              <Text as="p" small>
                <FormattedMessage
                  {...messages.contactUsBy}
                  values={{
                    phoneLink: system.parsedContactNumber &&
                      system.parsedContactNumber.formattedNumber && (
                        <FooterAnchor
                          href={`tel:${system.parsedContactNumber.formattedNumber}`}
                          style={{ whiteSpace: 'pre' }}
                        >
                          {system.parsedContactNumber.formattedNumber}
                        </FooterAnchor>
                      ),
                    emailLink: system.contactEmail && (
                      <FooterAnchor href={`mailto:${system.contactEmail}`}>
                        {system.contactEmail}
                      </FooterAnchor>
                    )
                  }}
                />
              </Text>
            )}
            {helpText && (
              <Text small>
                <BlockContent blocks={helpText} />
              </Text>
            )}
            {socialMediaLinks && (
              <Flex alignItems="center">
                {socialMediaLinks.map(socialMediaLink => {
                  if (!socialMediaLink.name) {
                    return null;
                  }
                  return (
                    <Box key={socialMediaLink._key}>
                      <SocialIconAnchor
                        color={color}
                        name={socialMediaLink.name}
                        href={socialMediaLink.url}
                      />
                    </Box>
                  );
                })}
              </Flex>
            )}
          </FooterCell>

          {navColumns[2] && (
            <MenuList navColumn={navColumns[2]} linkColor={color} />
          )}

          <FooterCell>
            {system.workingHours &&
              system.workingHours.endDate &&
              system.workingHours.startDate && (
                <Text small mb={5}>
                  <FormattedMessage
                    id="opening_hours"
                    defaultMessage="Opening hours: {0}"
                    values={{
                      0:
                        intl.formatDate(system.workingHours.startDate, {
                          hour: '2-digit',
                          minute: '2-digit',
                          hourCycle: 'h23'
                        }) +
                        ' - ' +
                        intl.formatDate(system.workingHours.endDate, {
                          hour: '2-digit',
                          minute: '2-digit',
                          hourCycle: 'h23'
                        })
                    }}
                  />
                </Text>
              )}
            {sponsor === 'obos' && (locale === 'en' || locale === 'nb') && (
              <Box mb="xs">
                <Anchor href="https://www.obos.no/">
                  <Image
                    src={`${sponsorsPath}/obos-${locale}.svg`}
                    alt={intl.formatMessage(messages.citybikePartner, {
                      sponsorName: 'OBOS'
                    })}
                  />
                </Anchor>
              </Box>
            )}
            {aboutOperator && (
              <Text small color={mutedColor}>
                <BlockContent
                  blocks={aboutOperator}
                  breakoutImages={false}
                  imageProps={{}}
                />
              </Text>
            )}
            {system.iosAppUrl && system.androidAppUrl && (
              <IconButton
                href={
                  isLondonGreenquarter
                    ? 'https://app.thegreenquarterbikes.co.uk'
                    : `/${locale}${appLinkProps.href}`
                }
                as="a"
                Icon={MobileIcon}
                placement="left"
                variant="inverted"
                shape="cool"
                small
              >
                {appLinkProps.children}
              </IconButton>
            )}
          </FooterCell>
        </FooterGrid>
      </Container>
    </Box>
  );
};

export default Footer;
