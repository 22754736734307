import { useContext, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useUnpaidOrders } from './useUnpaidOrders';
import { ToastContext } from '../../components/Toasts';
import { pushRoute } from '../../components/Link';
import { useRouter } from 'next/router';

const UNPAID_ORDERS_TOAST_ID = 'UNPAID_ORDERS_TOAST_ID';

const messages = defineMessages({
  unpaidOrderMessage: {
    id: 'UnpaidOrdersNotification.message',
    defaultMessage: 'Your card could not be charged.'
  },
  button: {
    id: 'UnpaidOrdersNotification.button',
    defaultMessage: 'Resolve'
  }
});

const receiptRoute = 'profile.receipts';

export const UnpaidOrdersNotification = () => {
  const intl = useIntl();
  const [, dispatch] = useContext(ToastContext);
  const router = useRouter();
  const { hasUnpaidOrders } = useUnpaidOrders();
  const { route } = router;
  useEffect(() => {
    if (route === '/' + receiptRoute) {
      return;
    }
    if (hasUnpaidOrders) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          id: UNPAID_ORDERS_TOAST_ID,
          text: intl.formatMessage(messages.unpaidOrderMessage),
          ttl: 0,
          primaryAction: {
            text: intl.formatMessage(messages.button),
            onClick: () => {
              dispatch({
                type: 'REMOVE_TOAST',
                payload: UNPAID_ORDERS_TOAST_ID
              });
              pushRoute({ route: receiptRoute, intl });
            }
          }
        }
      });
    }
  }, [hasUnpaidOrders, route]);
  return null;
};
