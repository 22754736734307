import {
  gql,
  QueryHookOptions,
  useQuery,
  ApolloError,
  ApolloQueryResult
} from '@apollo/client';
import { sanityQuery, sanityQueryVariables } from '../../core-types';
import { CustomNextPageContext } from '../types';
import { getLocaleFromContext } from '../utils';
import { useIntl } from 'react-intl';

const SANITY_QUERY = gql`
  query sanityQuery($query: String!, $params: JSON) {
    sanityQuery(query: $query, params: $params)
  }
`;

export const getBlockQuery = (name: string, field: string = name) => `
  "${name}": coalesce(${field}[$locale], ${field}.en)[] {
    ...,
    "slugsById": markDefs[_type == "page"]->{
      "slug": coalesce(slug[$locale], slug.en).current,
      _id,
      _type
    }
  }
`;

export const getFileQuery = (fieldName: string) => `
  "${fieldName}": {
    ...${fieldName},
    "internalPage": ${fieldName}.internalPage->{
      header,
      "slug": slug.current
    },
    "file": file.asset->url
  }
`;

export function useSanityQuery<TData>(
  options: QueryHookOptions<sanityQuery, sanityQueryVariables>
): {
  data: TData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { locale } = useIntl();
  if (!options.variables) {
    throw new Error('Variables not defined');
  }
  const queryResult = useQuery<sanityQuery, sanityQueryVariables>(
    SANITY_QUERY,
    {
      ...options,
      variables: {
        query: options.variables.query,
        params: {
          ...options.variables.params,
          locale
        }
      }
    }
  );
  return {
    data: queryResult.data?.sanityQuery,
    loading: queryResult.loading,
    error: queryResult.error
  };
}

export async function apolloClientSanityQuery<TData>(
  ctx: CustomNextPageContext,
  variables: sanityQueryVariables
): Promise<ApolloQueryResult<TData>> {
  const locale = getLocaleFromContext(ctx);
  const articlePageQueryResult = await ctx.apolloClient.query<
    sanityQuery,
    sanityQueryVariables
  >({
    query: SANITY_QUERY,
    variables: {
      query: variables.query,
      params: { ...variables.params, locale }
    }
  });

  const data: TData =
    articlePageQueryResult.data && articlePageQueryResult.data.sanityQuery;

  return { ...articlePageQueryResult, data };
}
