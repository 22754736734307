import { useState, useEffect } from 'react';
import isAfter from 'date-fns/isAfter';
import addMinutes from 'date-fns/addMinutes';

const isServer = typeof window === 'undefined';

const key = 'twilioVerification';
const expiresInMinutes = 10;

type Value = {
  twilioVerificationSid: string;
  nationalPhoneNumber: string;
  prefix: string;
  expiresAt: string;
};

function removeItem(): void {
  try {
    if (!isServer) {
      localStorage.removeItem(key);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Could not removeItem', err);
  }
}

export function useStoredVerification(): [
  Value | null,
  (string) => void,
  () => void
] {
  let initialValue: Value | null = null;

  try {
    if (!isServer) {
      const item = localStorage.getItem(key);
      if (item) {
        initialValue = JSON.parse(item);
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Could not getItem', err);
  }

  if (initialValue) {
    if (isAfter(new Date(), new Date(initialValue.expiresAt))) {
      removeItem();
      initialValue = null;
    }
  }

  const [value, setValue] = useState<Value | null>(initialValue);

  useEffect(() => {
    if (value === null) {
      return;
    }
    try {
      if (!isServer) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Could not setItem', err);
    }
  }, [value, key]);

  function setVerificationSid({
    twilioVerificationSid,
    nationalPhoneNumber,
    prefix
  }: {
    twilioVerificationSid: string;
    nationalPhoneNumber: string;
    prefix: string;
  }) {
    setValue({
      twilioVerificationSid,
      nationalPhoneNumber,
      prefix,
      expiresAt: addMinutes(new Date(), expiresInMinutes).toISOString()
    });
  }

  return [value, setVerificationSid, removeItem];
}
