import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const href = '/app';

export const useAppLinkProps = (): {
  href: string;
  children: React.ReactNode;
} => {
  return {
    href,
    children: (
      <FormattedMessage id="components.AppLink" defaultMessage="Get the app" />
    )
  };
};
