import { defineMessages, FormattedMessage } from 'react-intl';
import { Anchor } from '../../components';

const messages = defineMessages({
  status: {
    id: 'system_status',
    defaultMessage: 'System status'
  }
});

const OperationalStatus = () => {
  return (
    <Anchor color="text" href="https://status.urbansharing.com">
      <FormattedMessage {...messages.status} />
    </Anchor>
  );
};

export default OperationalStatus;
