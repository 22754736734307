import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css, keyframes } from 'styled-components';
import {
  Menu as MenuIcon,
  RemoveAlt as CloseMenuIcon
} from '@urbaninfrastructure/react-icons';
import {
  Flex,
  Box,
  Button,
  Hide,
  Anchor,
  Link,
  Container
} from '../../components';
import Logo from '../../components/Logo';
import { Navigation } from './Navigation';
import { SystemConfigConsumer } from '../../lib/system-config-context';
import { settings_settings, settings_system } from '../../core-types';
import { ButtonAnchor } from '@urbaninfrastructure/react-ui-kit';
import { ClosedNotification } from './ClosedNotification';

type Props = {
  settings: settings_settings;
  system: settings_system;
  title: string | null;
  isStartPage: boolean;
  isLoggedIn: boolean;
};

const messages = defineMessages({
  menuClose: {
    id: 'components.Layout.Header.menuClose',
    defaultMessage: 'Close menu'
  },
  menu: {
    id: 'components.Layout.Header.menu',
    defaultMessage: 'Menu'
  }
});

const CTAButtons = ({
  settings: { header },
  menuOn
}: {
  settings: settings_settings;
  menuOn: boolean;
}) => {
  if (!header) {
    return null;
  }

  const { ctaButton } = header;

  if (!ctaButton || !ctaButton.title) {
    return null;
  }

  const variant = menuOn ? 'inverted' : 'primary';
  const anchorProps = {
    small: true,
    mt: { xs: 7, xl: 0 },
    ml: { xl: 4 },
    children: ctaButton.title
  };

  if (ctaButton.page) {
    const ctaLinkProps = {
      route: ctaButton.page._type,
      passHref: true
    };

    return (
      <Link {...ctaLinkProps}>
        <ButtonAnchor variant={variant} shape="cool" {...anchorProps} />
      </Link>
    );
  }

  if (ctaButton.externalLink) {
    return (
      <ButtonAnchor
        variant={variant}
        shape="cool"
        href={ctaButton.externalLink}
        {...anchorProps}
      />
    );
  }

  if (ctaButton.showIntercom) {
    return (
      <Button
        variant={variant}
        {...anchorProps}
        shape="cool"
        onClick={event => {
          event.preventDefault();
          if (window.Intercom) {
            window.Intercom('show');
          }
        }}
      />
    );
  }

  return null;
};

const fontSizeKey = 2;

const wrapperAnimation = keyframes`
0%   { background-color: transparent; }
100% { background-color: ${
  // @ts-ignore
  ({ theme }) => theme.colors.primary
}; }`;

const wrapperBeforeAnimation = keyframes`
0%   { transform: scale(0.1) }
100% { transform: scale(1.1) }`;

const HeaderBox = styled(Box)<{
  menuOn: boolean;
}>`
  ${({ theme, menuOn }) => css`
    z-index: 2;
    ${menuOn &&
      css`
        background-color: ${theme.colors.primary};
        color: white;
        min-height: 100vh;
        animation: ${wrapperAnimation} 0.4s
          cubic-bezier(0.895, 0.03, 0.685, 0.22);
        animation-fill-mode: both;
        position: relative;
        overflow: hidden;
        /* Background circle animation on toggeling */
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -95vmax;
          right: -95vmax;
          width: 200vmax;
          height: 200vmax;
          background: ${theme.colors.primary};
          border-radius: 50%;
          animation: ${wrapperBeforeAnimation} 0.5s
            cubic-bezier(0.165, 0.84, 0.44, 1);
          animation-fill-mode: both;
        }
      `};
  `};
`;

const ToggleMenuButton = styled(Button)<{
  isStartPage: boolean;
  navLinkColor: string;
}>`
  ${({ theme, navLinkColor, isStartPage, ...props }) =>
    css`
      display: flex;
      justifycontent: center;
      alignitems: center;
      padding-left: ${theme.space[2]}px;
      padding-right: ${theme.space[2]}px;
      background-color: transparent;
      color: ${theme.colors[navLinkColor]};
      &:hover,
      &:focus {
        background-color: ${isStartPage
          ? `rgba(0, 0, 0, 0.2)`
          : theme.colors.neutral[1]};
        color: ${theme.colors[navLinkColor]};
        outline: none;
      }

      ${props['aria-expanded'] &&
        css`
          background-color: transparent;
          color: white;
          z-index: 2;
          position: relative;
          &:hover,
          &:focus {
            background-color: ${theme.colors.primaryDark};
            color: white;
          }
        `};
    `};
`;

const menuItemsAnimation = keyframes`
0%   { opacity: 0; transform: translateX(20%);  }
100% { opacity: 1; transform: translateX(0); }
`;

const MenuFlexWrapper = styled(Flex)<{ menuOn: boolean }>`
  ${({ theme, menuOn }) =>
    css`
      display: none;
      ${theme.mediaQueries[3]} {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      ${menuOn &&
        css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: ${theme.space[5]}px 0;
          & > * > * {
            animation: ${menuItemsAnimation} 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            animation-fill-mode: both;
            &:nth-child(1) {
              animation-delay: 0.1s;
            }
            &:nth-child(2) {
              animation-delay: 0.15s;
            }
            &:nth-child(3) {
              animation-delay: 0.2s;
            }
            &:nth-child(4) {
              animation-delay: 0.25s;
            }
            &:nth-child(5) {
              animation-delay: 0.3s;
            }
            &:nth-child(6) {
              animation-delay: 0.35s;
            }
          }
        `};
    `};
`;

const Header: React.FC<Props> = ({
  title,
  isStartPage,
  settings,
  system,
  isLoggedIn
}) => {
  const intl = useIntl();
  const [menuOn, setMenuOn] = React.useState(false);

  const textColor = isStartPage ? 'white' : 'text';
  const navLinkColor = isStartPage ? 'white' : 'link';

  return (
    <>
      <ClosedNotification closedReason={system.closedReason} />
      <HeaderBox
        data-testid="header"
        color={textColor}
        fontSize={fontSizeKey}
        position={isStartPage ? 'absolute' : 'relative'}
        width={1}
        menuOn={menuOn}
      >
        <Container bleed fullWidth px="xs">
          <Flex
            alignItems={{ xl: 'center' }}
            flexDirection={{ _: 'column', xl: 'row' }}
            mx="auto"
            data-testid="flex"
          >
            <Flex position="relative" alignItems="center" flex="1 0 auto">
              <Box id="head" flex="1 0 auto" pt={2} pb={2}>
                <Link route="home" passHref>
                  <Anchor
                    py={3}
                    textDecoration="none"
                    display="inline-block"
                    color={navLinkColor}
                  >
                    <Logo title={title} color={navLinkColor} />
                  </Anchor>
                </Link>
              </Box>
              <Hide xl>
                <ToggleMenuButton
                  onClick={() => {
                    setMenuOn(!menuOn);
                  }}
                  aria-expanded={menuOn}
                  my={3}
                  ml="auto"
                  variant="reset"
                  shape="cool"
                  small
                  isStartPage={isStartPage}
                  data-testid="Header__ToggleMenuButton"
                  navLinkColor={navLinkColor}
                  aria-label={intl.formatMessage(
                    menuOn ? messages.menuClose : messages.menu
                  )}
                >
                  {menuOn ? <CloseMenuIcon /> : <MenuIcon />}
                </ToggleMenuButton>
              </Hide>
            </Flex>
            <MenuFlexWrapper
              data-testid="Header__NavigationWrapper"
              menuOn={menuOn}
            >
              <SystemConfigConsumer>
                {({ systemConfig }) => {
                  const { enableLogin } = systemConfig.featureFlags;
                  const { navigation } = settings;
                  return navigation ? (
                    <Navigation
                      items={navigation}
                      enableLogin={enableLogin}
                      isLoggedIn={isLoggedIn}
                    />
                  ) : null;
                }}
              </SystemConfigConsumer>
              <Flex alignItems="center">
                <CTAButtons settings={settings} menuOn={menuOn} />
              </Flex>
            </MenuFlexWrapper>
          </Flex>
        </Container>
      </HeaderBox>
    </>
  );
};

export default Header;
