import * as React from 'react';
import Link from '../../components/Link';

type Props = {
  children: React.ReactNode;
  page: {
    __typename: 'SanitySettingsNavigationPage';
    _id: string;
    _type: string;
    slug: {
      __typename: 'SanitySlug';
      current: string;
    } | null;
  };
};

export const NavigationLink = ({ page, children }: Props) => {
  const slug = page.slug ? page.slug.current : '';
  const params: { slug?: string } = {};

  if (slug) {
    params.slug = slug;
  }

  return (
    <Link route={page._type} params={params} passHref>
      {children}
    </Link>
  );
};
