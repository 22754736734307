import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApolloError } from '@apollo/client';
import {
  Flex,
  Box,
  Text,
  Heading,
  Anchor,
  Grid,
  GridCell,
  Link,
  AnchorButton
} from '../../components';
import { useIntercom } from '../../lib/intercom';
import OperationalStatus from './OperationalStatus';

type Props = {
  error?: Error | ApolloError;
  errorType: 'pageNotFound' | 'coreError' | 'unknown';
  statusCode: number;
  intercomId?: string;
};

const RefreshMessage = () => (
  <Text as="p">
    <FormattedMessage
      id="modules.ErrorPage.RefreshMessage.message"
      defaultMessage="Sorry about that. Please try refreshing and contact us if the problem persist."
    />
  </Text>
);

const ErrorHeading = (props: any) => <Heading mb={4} {...props} />;
const SystemError = ({ heading }: { heading: React.ReactNode }) => {
  return (
    <>
      <Text fontSize={60} mb="sm">
        😰
      </Text>
      <ErrorHeading>{heading}</ErrorHeading>
      <RefreshMessage />
    </>
  );
};
const PageNotFound = () => {
  return (
    <>
      <Text fontSize={60} mb="sm">
        🤔
      </Text>
      <ErrorHeading>
        <FormattedMessage
          id="modules.ErrorPage.PageNotFound.heading"
          defaultMessage="The page could not be found"
        />
      </ErrorHeading>
      <FormattedMessage
        id="modules.ErrorPage.PageNotFound.text"
        defaultMessage="Check that the address entered is correct, or go to the <link>front page</link>"
        values={{
          link: function StartPageLink(...chunks) {
            return (
              <Link route="startPage" passHref>
                <Anchor color="text">{chunks}</Anchor>
              </Link>
            );
          }
        }}
      />
    </>
  );
};
const FailedToFetch = () => {
  return (
    <SystemError
      heading={
        <FormattedMessage
          id="modules.ErrorPage.FailedToFetch.heading"
          defaultMessage="Could not load content"
        />
      }
    />
  );
};
const UnknownError = () => {
  return (
    <SystemError
      heading={
        <FormattedMessage
          id="modules.ErrorPage.UnknownError.heading"
          defaultMessage="An unknown error has occured"
        />
      }
    />
  );
};
const ErrorPage = ({ errorType, intercomId }: Props) => {
  useIntercom(false);
  let component;
  switch (errorType) {
    case 'coreError': {
      component = <FailedToFetch />;
      break;
    }
    case 'pageNotFound': {
      component = <PageNotFound />;
      break;
    }
    default: {
      component = <UnknownError />;
    }
  }
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minHeight="100vh"
        column
      >
        <Box mb={4}>{component}</Box>
        <Text small>
          <Grid>
            <GridCell>
              <OperationalStatus />
            </GridCell>
            {intercomId && (
              <GridCell>
                <AnchorButton
                  onClick={event => {
                    event.preventDefault();
                    if (window.Intercom) {
                      window.Intercom('show');
                    }
                  }}
                  color="text"
                >
                  <FormattedMessage
                    id="modules.ErrorPage.footer.contactUs"
                    defaultMessage="Contact us"
                  />
                </AnchorButton>
              </GridCell>
            )}
          </Grid>
        </Text>
      </Flex>
    </>
  );
};
ErrorPage.defaultProps = {
  statusCode: 500,
  errorType: 'unknown'
};
export default ErrorPage;
