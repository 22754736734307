import { CustomNextPageContext } from './types';

export function getQueryString(
  str: string | string[] | undefined
): string | undefined {
  if (Array.isArray(str)) {
    return str[0];
  }
  return str;
}

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getLocaleFromContext(ctx: CustomNextPageContext): string {
  if (ctx.req) {
    return ctx.req.locale;
  }
  let locale = getQueryString(ctx.query.locale);
  if (!locale) {
    locale = 'en';
  }
  return locale;
}

//This is a hack for ATB/OBOS campaigns. Remove when campaign is over.
export function hideproductSlugFromRequest(
  productSlug: string | string[] | undefined
) {
  if (!productSlug) {
    return;
  }

  const products = {
    'at-b-free': '/atb/campaign',
    'at-b-gratis': '/atb/kampanje',
    'obos-365-day-pass': '/obos/campaign',
    'obos-365-dagerspass': '/obos/kampanje',
    'obos-season-pass-2024': '/obos/campaign',
    'obos-sesongpass-2024': '/obos/kampanje',
    'obos-30-day-subscription': '/obos/campaign',
    'obos-30-dagers-abonnement': '/obos/kampanje'
  };

  for (const key in products) {
    if (productSlug.includes(key)) {
      history.replaceState({}, key, products[key]);
      break;
    }
  }
}
