import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from '../../components';
import { FlexProps } from '@urbaninfrastructure/react-ui-kit';

export const Content = styled(Box)`
  ${({ center }: { center?: boolean }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `};
`;

Content.displayName = 'Content';

Content.defaultProps = {
  flex: '1 0 auto',
  mx: [4, 5],
  mb: [3, 5],
  py: [null, 5],
  minHeight: '80vh'
};

export const Wrap: FC<FlexProps> = ({ color, ...props }) => (
  <Flex
    minHeight="100vh"
    justifyContent="center"
    flexDirection="column"
    color={color as any}
    {...props}
  />
);
