import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Flex, Link } from '../../components';
import { i18nMessages } from '../../components/common-i18n';
import { globalMessages } from '../../lib/messages';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { useStyledTheme } from '../../lib/hooks';
import { NavLink } from './NavLink';
import { useDimensions } from '../../lib/use-dimensions';
import { ProfileAlt } from '@urbaninfrastructure/react-icons';

export const LogInOrProfile = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const theme = useStyledTheme();
  const reduceMotion = useReducedMotion();
  const [ref, dimensions] = useDimensions({
    liveMeasure: false
  });
  const [position, setPosition] = useState(false);
  let width: number | undefined;

  useEffect(() => {
    if (
      isLoggedIn &&
      !position &&
      window.matchMedia(`(min-width: ${theme.breakpoints.lg})`).matches
    ) {
      setPosition(true);
    }
  }, [position, isLoggedIn, theme.breakpoints.lg]);

  width = dimensions.width;

  return (
    <motion.div
      initial={true}
      animate={width && position ? { width } : {}}
      transition={{ delay: 0.3 }}
      style={{
        width: width && position ? width : undefined,
        position: 'relative'
      }}
    >
      <AnimatePresence initial={false}>
        <Box
          as={motion.div}
          ref={ref}
          onAnimationStart={() => {
            setPosition(true);
          }}
          onAnimationEnd={() => {
            setPosition(false);
          }}
          key={isLoggedIn ? 'loggedIn' : 'loggedOut'}
          position="relative"
          initial={{
            opacity: 0,
            y: reduceMotion ? 0 : -100
          }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: reduceMotion ? 0 : -100
          }}
        >
          {!isLoggedIn && (
            <Link route="log-in" passHref>
              <NavLink data-testid="Header__LogIn">
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <ProfileAlt
                    color={{ _: 'white', xl: 'primaryIcon' }}
                    size={20}
                    mr={1}
                  />
                  <FormattedMessage {...globalMessages.logIn} />
                </Flex>
              </NavLink>
            </Link>
          )}
          {isLoggedIn && (
            <Link route="profile" passHref>
              <NavLink data-testid="Header__ProfileLink">
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <ProfileAlt
                    color={{ _: 'white', xl: 'primaryIcon' }}
                    size={20}
                    mr={1}
                  />
                  <FormattedMessage {...i18nMessages.profile} />
                </Flex>
              </NavLink>
            </Link>
          )}
        </Box>
      </AnimatePresence>
    </motion.div>
  );
};
