import Head from 'next/head';

export type Props = {
  title: string | null;
  description: string | null;
  images: Array<string | null> | null;
};

const OpenGraphTags = (props: Props) => {
  return (
    <Head>
      {props.description && (
        <meta
          key="meta-description"
          name="description"
          content={props.description}
        />
      )}
      {Object.keys(props).map(key => {
        // next.js applies this to all elements in Head for some reason
        if (key === 'className') {
          return null;
        }

        const value = props[key];

        if (!value) {
          return null;
        }

        if (key === 'images' && Array.isArray(value)) {
          return value.map(image => {
            if (!image) {
              return null;
            }
            return (
              <meta
                key={`og-image-${image}`}
                property={`og:image`}
                content={`${image}?w=1600`}
              />
            );
          });
        }

        return (
          <meta key={`og-${key}`} property={`og:${key}`} content={value} />
        );
      })}
    </Head>
  );
};

export default OpenGraphTags;
