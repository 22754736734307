import { gql } from '@apollo/client';

export const ARTICLE_PAGE_QUERY = gql`
  query articlePageQuery($systemId: ID, $locale: String, $slug: String!) {
    articlePage: articlePageBySlug(
      systemId: $systemId
      locale: $locale
      slug: $slug
    ) {
      title
      file
      heading
      intro
      content
      slugsByLocale {
        locale
        slug
      }
    }
  }
`;
