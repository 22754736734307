import styled, { css } from 'styled-components';
import { Search as SearchIcon } from '@urbaninfrastructure/react-icons';
import { Button, Box, Hide, Input } from '../../components';

const ICON_SIZE = 17; // px
const INPUT_HEIGHT = 40; // px

const StyledInput = styled(Input)`
  ${({ theme }) => {
    return css`
      background: ${theme.colors.borderGray};
      border-radius: ${INPUT_HEIGHT}px;
      height: ${INPUT_HEIGHT}px;
      ::placeholder {
        color: ${theme.colors.accent.purple};
      }
      &:focus {
        background: ${theme.colors.white};
        border-color: transparent;
      }
    `;
  }};
`;

const SearchButton = styled(Button)`
  padding: 0 1rem;
  position: absolute;
  right: 0;
  top: 0;
  height: ${INPUT_HEIGHT}px;
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

export default function IntercomSearchForm({
  intercomHelpSite,
  placeholder
}: {
  intercomHelpSite: string;
  placeholder: string;
}) {
  return (
    <Box position="relative" mb="xs">
      <form method="get" action={intercomHelpSite}>
        <StyledInput
          name="q"
          aria-label={placeholder}
          placeholder={placeholder}
          autoComplete="off"
          py={3}
          pl={4}
          pr={ICON_SIZE * 2 + 'px'}
        />
        <SearchButton variant="reset">
          <Hide srOnly all>
            {placeholder}
          </Hide>
          <SearchIcon size={ICON_SIZE} />
        </SearchButton>
      </form>
    </Box>
  );
}
