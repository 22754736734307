import { useEffect } from 'react';
import * as React from 'react';
import Head from 'next/head';
import { FormattedMessage } from 'react-intl';
import { useSettingsQuery } from '../../lib/core/SettingsQuery';
import { useSystemConfig } from '../../lib/system-config-context';
import { Router } from '../../lib/routes';
import { extractIOSAppId, isAppContent } from '../../lib/util';
import ErrorPage from '../ErrorPage';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import { Loading } from '../../components';
import Alert from '../../components/Alert';
import { Box } from '../../components';
import Header from './Header';
import Footer from './Footer';
import { Intercom } from '../../lib/intercom';
import OpenGraphTags, { Props as OpenGraphProps } from './OpenGraphTags';
import { Content, Wrap } from './styled';
import { NextRouter } from 'next/router';
// @ts-ignore svg
import EmitionNegativeSceptical from '../../public/static/assets/emotions/negative_sceptical.svg';
import { useCurrentUser } from '../../lib/core/useCurrentUser';
import { CookiesNotification } from './CookiesNotification';
import { Lock } from '@urbaninfrastructure/react-icons';
import { UnpaidOrdersNotification } from '../UnpaidOrders/UnpaidOrdersNotification';
import { dinamicRenderingConfig } from '../../lib/server/middlewares/system-config/dinamic-rendering';
import SystemAlert from '../../components/SystemAlert';
import { useSystemAlertsQuery } from '../../lib/core/SystemAlertsQuery';
import { routeConfig } from '../../lib/routes/config';
import { containsPageSlug } from '../../utils/pageSlugCheck';

type Props = {
  type?: 'article' | 'checkout' | 'default' | 'startPage';
  centerContent?: boolean;
  title?: string | null;
  loading: boolean;
  error?: React.ReactNode;
  url: NextRouter;
  requiresAuth?: boolean;
  render: (renderProps: {
    loading: boolean;
    appContent: boolean;
  }) => React.ReactNode;
  opengraph?: OpenGraphProps | null;
  contentProps?: Record<string, any>;
  hideIntercomLauncher?: boolean;
  hideChrome?: boolean;
  statusCode?: number;
};

function Layout(props: Props) {
  let { loading } = props;
  const {
    url,
    title,
    error,
    render,
    type,
    centerContent,
    requiresAuth,
    opengraph,
    contentProps,
    hideIntercomLauncher,
    statusCode
  } = props;

  const systemConfig = useSystemConfig();
  const settingsQueryProps = useSettingsQuery();
  const { currentUser, ...currentUserQueryResult } = useCurrentUser();
  const systemAlerts = useSystemAlertsQuery();
  const settingsQueryError = settingsQueryProps.error;

  const redirectToLogIn =
    requiresAuth && !currentUserQueryResult.loading && !currentUser;

  useEffect(() => {
    if (redirectToLogIn) {
      // not allowed, redirect to login page after n seconds
      const timeoutId = setTimeout(() => {
        Router.push({
          pathname: '/log-in',
          query: { returnRoute: url?.asPath }
        });
      }, 1500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [redirectToLogIn, url?.asPath]);

  if (settingsQueryError && settingsQueryError.networkError) {
    return (
      <ErrorPage
        errorType="coreError"
        error={settingsQueryError}
        intercomId={systemConfig && systemConfig.intercomId}
      />
    );
  }

  if (statusCode && statusCode >= 400) {
    return (
      <ErrorPage
        statusCode={statusCode}
        errorType="pageNotFound"
        intercomId={systemConfig && systemConfig.intercomId}
      />
    );
  }

  const loadingComponent = <Loading />;

  if (requiresAuth && !loading) {
    loading = currentUserQueryResult.loading;
  }

  if (settingsQueryProps.loading) {
    return (
      <Wrap>
        <Content center>{loadingComponent}</Content>
      </Wrap>
    );
  }

  if (!settingsQueryProps.data) {
    throw new Error('Could not load data');
  }

  if (!settingsQueryProps.data.settings) {
    throw new Error('Could not load settings');
  }

  if (!settingsQueryProps.data.system) {
    throw new Error('Could not load system');
  }

  const { settings, system } = settingsQueryProps.data;

  const appContent = isAppContent(url.query);

  if (redirectToLogIn) {
    return (
      <Wrap data-testid="Layout__RedirectToLogin">
        <Content center>
          {systemConfig.useOby && (
            <Text color="primary">
              <EmitionNegativeSceptical width={200} />
            </Text>
          )}
          {!systemConfig.useOby && (
            <Box mb="xs">
              <Lock color="primaryIcon" size={60} />
            </Box>
          )}
          <FormattedMessage
            id="modules.Profile.ProfileLayout.protected"
            defaultMessage="This page requires login, redirecting you to the login page"
          />
        </Content>
      </Wrap>
    );
  }

  const isStartPage = type === 'startPage';
  // hide header/footer
  const hideHeaderAndFooter = dinamicRenderingConfig.find(
    config =>
      config.systemId === system?.id &&
      config.showFooter === false &&
      config.showHeader === false
  );
  const hideChrome = appContent || props.hideChrome || hideHeaderAndFooter;
  let showHeader = true;
  let showFooter = type !== 'checkout';
  if (hideChrome) {
    showHeader = showFooter = false;
  }
  if (isStartPage) {
    showHeader = showFooter = false;
  }

  const appId = system.iosAppUrl && extractIOSAppId(system.iosAppUrl);

  const isSystemStatusPage = containsPageSlug(
    url.asPath,
    Object.values(routeConfig['system-status'].slug || {})
  );

  return (
    <Wrap>
      <Head>
        <title>{title ? `${title} - ${settings.title}` : settings.title}</title>
        {appId && <meta name="apple-itunes-app" content={`app-id=${appId}`} />}
        {system.publicDomain && system.GBFS && (
          <link
            key="gbfs-tag"
            rel="gbfs"
            type="application/json"
            href={`https://gbfs.urbansharing.com/${system.publicDomain}/gbfs.json`}
          />
        )}
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <UnpaidOrdersNotification />
      {opengraph && (
        <OpenGraphTags
          title={opengraph.title}
          description={opengraph.description}
          images={opengraph.images}
        />
      )}
      {!isSystemStatusPage && (
        <SystemAlert data={systemAlerts.data} error={systemAlerts.error} />
      )}
      {showHeader && (
        <Header
          title={settings.title}
          isStartPage={isStartPage}
          settings={settings}
          system={system}
          isLoggedIn={!!currentUser}
        />
      )}
      {error && (
        <Flex
          flex="1 0 0%"
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          <Alert variant="error">{error}</Alert>
        </Flex>
      )}
      {loading && (
        <Flex
          flex="1 0 0%"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh"
        >
          {loadingComponent}
        </Flex>
      )}
      {!loading && (
        <Content
          center={centerContent}
          mt={appContent ? 5 : undefined}
          {...contentProps}
        >
          {render({ loading, appContent })}
        </Content>
      )}
      {showFooter && (
        <Footer
          system={system}
          currentUser={currentUser}
          footer={settings.footer}
          enableLogin={systemConfig.featureFlags.enableLogin}
        />
      )}
      {settings.cookieNotification && !appContent && (
        <CookiesNotification
          cookieNotification={settings.cookieNotification}
          strictlyNecessaryCookiesContent={
            settings.strictlyNecessaryCookiesContent
          }
          performanceCookiesContent={settings.performanceCookiesContent}
          functionalCookiesContent={settings.functionalCookiesContent}
          targetingCookiesContent={settings.targetingCookiesContent}
          onClose={data => {
            if (!data) return;

            if (data.targeting) {
              location.reload();
            }
          }}
        />
      )}
      <Intercom hideIntercomLauncher={hideIntercomLauncher} />
    </Wrap>
  );
}

export default Layout;
