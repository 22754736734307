import React from 'react';
import { SystemAlertVisibility } from '../core-types';
import { Anchor, Box, H3, Text } from '@urbaninfrastructure/react-ui-kit';
import { RemoveAlt as CloseIcon } from '@urbaninfrastructure/react-icons';
import { Link } from '../components';
import { isBefore, parseISO } from 'date-fns';
import styled from 'styled-components';

type StateEnum =
  | 'resolved'
  | 'active_now'
  | 'active_to'
  | 'scheduled_from'
  | 'scheduled_from_to'
  | 'ended_at';

const styles = `
@keyframes slideUp {
  from {
    transform: translate(0%, -100%);
  }
  to {
    transform: translate(0%, 0%);
  }
}

@keyframes slideDown {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(0%, -100%);
  }
}

.box {
  transition: transform 0.5s ease-in-out;
}

.box.visible {
  animation: slideUp 0.5s forwards;
}

.box.closing {
  animation: slideDown 0.5s ease-in-out;
}
`;

const SystemAlertWrapper = styled(Box)`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryContrast};
  padding: 1.5rem 2rem;
`;

const StyledBox = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const getSystemAlertState = (now: Date, systemAlert): StateEnum => {
  const { incidentEndTime, incidentStartTime, resolvedAt } = systemAlert;

  if (resolvedAt !== null) {
    return 'resolved';
  }

  if (incidentStartTime && isBefore(now, parseISO(incidentStartTime))) {
    return incidentEndTime ? 'scheduled_from_to' : 'scheduled_from';
  }

  if (incidentEndTime) {
    return isBefore(now, parseISO(incidentEndTime)) ? 'active_to' : 'ended_at';
  }

  return 'active_now';
};

export function setLocalStorageItems(key, value) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

function getLocalStorageItems(key: string) {
  if (typeof window !== 'undefined') {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue);
    } else {
      return null;
    }
  }
}

function containsSystemAlertId(
  storedSystemAlerts: string[] | null,
  id: string
): boolean {
  if (storedSystemAlerts === null) {
    return false;
  }
  return storedSystemAlerts.includes(id);
}

const SystemAlert = ({ data, error }) => {
  const [closed, setClosed] = React.useState(false);
  const storedSystemAlerts = getLocalStorageItems('systemAlerts');

  if (!data || !data.systemAlerts || error) {
    return null;
  }

  // Show only alerts that are public and not in the local storage
  const systemAlerts = data.systemAlerts.concat(data.systemClosureAlerts);

  const publicSystemAlerts = systemAlerts.filter(
    alert =>
      (getSystemAlertState(new Date(), alert) === 'active_now' ||
        getSystemAlertState(new Date(), alert) === 'active_to') &&
      (alert.visibility === SystemAlertVisibility.public_all ||
        alert.visibility === SystemAlertVisibility.public_web) &&
      containsSystemAlertId(storedSystemAlerts, alert.id) === false
  );

  const publicSystemAlertsIds = publicSystemAlerts.map(alert => alert.id);

  const publicSystemAlertsFiltered = publicSystemAlerts.filter(alert => {
    if (storedSystemAlerts === null) {
      return alert;
    }

    return !storedSystemAlerts.includes(alert.id);
  });

  const handleClose = () => {
    setLocalStorageItems('systemAlerts', publicSystemAlertsIds);
    setClosed(true);
  };

  return (
    <>
      <style>{styles}</style>
      {publicSystemAlertsFiltered.length > 0 && (
        <SystemAlertWrapper className={`box ${closed ? 'closing' : 'visible'}`}>
          {/**Temporarily set display none and make alerts be displayed continuously */}
          <Box
            data-testid="System-Alert-Close"
            className="system-alertclose-button"
            mb="xs"
            position={'absolute'}
            top={'1.25rem'}
            right={'1.25rem'}
            display={'none'}
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon size="1rem" color="primaryContrast" />
          </Box>
          <Box className="system-alert system-alert-warning" role="alert">
            {publicSystemAlertsFiltered.map(alert => (
              <StyledBox key={alert.id} mb={3}>
                <Link route="system-status" passHref>
                  <Anchor color="primaryContrast">
                    <H3 lineHeight={1} fontWeight={500} mb={'5px'}>
                      {alert.title}
                    </H3>
                  </Anchor>
                </Link>
                <Text fontSize={1} mb={0}>
                  {alert.description}
                </Text>
              </StyledBox>
            ))}
          </Box>
        </SystemAlertWrapper>
      )}
    </>
  );
};

export default SystemAlert;
