import { useState, useCallback, useLayoutEffect, useEffect } from 'react';

export interface DimensionObject {
  width?: number;
  height?: number;
}

type UseDimensionsHook = [
  (node: HTMLElement | null) => void,
  DimensionObject,
  HTMLElement | null
];

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height
  };
}

export function useDimensions({
  liveMeasure = true,
  enableSSR = typeof window === 'undefined'
}: {
  liveMeasure?: boolean;
  enableSSR?: boolean;
} = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState<HTMLElement | null>(null);

  const useLayoutHookBasedOnEnvironment = enableSSR
    ? useEffect
    : useLayoutEffect;

  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useLayoutHookBasedOnEnvironment(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node]);

  return [ref, dimensions, node];
}
