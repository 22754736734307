import { useQuery } from '@apollo/client';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { getSlugs } from '../../components/Link';
import {
  LanguageCode,
  articlePageQuery,
  articlePageQueryVariables
} from '../../core-types';
import { ARTICLE_PAGE_QUERY } from '../../lib/core/ArticlePageQuery';
import { useStaticDataSystem } from '../../lib/core/staticDataQuery';
import { match } from '../../lib/routes';
import { getQueryString } from '../../lib/utils';
import { ClickAwayListener } from '@material-ui/core';
import { useTheme } from 'styled-components';
import { Down as DownIcon } from '@urbaninfrastructure/react-icons';
import { Box as BoxContainer } from '../../components';

type RenderProps = (renderProps: {
  anchorProps: {
    href: string;
    children: string;
  };
}) => React.ReactElement;

const longLocaleMessages: Record<LanguageCode, string> = {
  en: 'Switch to English',
  nb: 'Bytt til norsk',
  it: "Passa all'italiano",
  fr: 'Changer en français',
  de: 'Wechseln Sie zu Deutsch',
  pl: 'Przełącz na polski',
  uk: 'Переключитися на українську',
  sv: 'Byt till svenska',
  es: 'Cambiar a español',
  fi: 'Vaihda suomeksi'
};

const shortLocaleMessages: Record<LanguageCode, string> = {
  en: 'English',
  nb: 'Norsk',
  it: 'Italiano',
  fr: 'Français',
  de: 'Deutsch',
  pl: 'Polski',
  uk: 'Українська',
  sv: 'Svenska',
  es: 'Español',
  fi: 'Suomi'
};

export const ChangeLocaleLinkStateless = ({
  routeMatch,
  defaultLocale,
  slug,
  slug2,
  nextLocale,
  messageLength,
  children
}: {
  routeMatch: {
    route: {
      name: string;
      getAs: (arg: Record<string, any>) => string | null;
    };
    query: Record<string, any>;
  };
  slug?: string;
  slug2?: string;
  defaultLocale: string;
  locale: string;
  nextLocale: string;
  messageLength: 'short' | 'long';
  children: RenderProps;
}) => {
  if (!nextLocale) {
    return null;
  }

  const routeParams: {
    locale?: string;
    slug?: string;
    slug2?: string;
  } = {
    ...routeMatch.query,
    locale: nextLocale
  };
  if (slug) {
    routeParams.slug = slug;
  }
  if (slug2) {
    routeParams.slug2 = slug2;
  }
  // if next locale is defaultLocale, don't print it
  if (defaultLocale === nextLocale) {
    delete routeParams.locale;
  }
  let href = routeMatch.route.getAs(routeParams);

  if (href && href.endsWith('?')) {
    href = href.slice(0, -1);
  }

  const messages =
    messageLength === 'short' ? shortLocaleMessages : longLocaleMessages;

  if (!messages[nextLocale]) {
    return null;
  }

  const anchorProps = {
    href: href || '',
    children: messages[nextLocale]
  };

  return children({ anchorProps });
};

export function ChangeLocaleLink({
  messageLength,
  children,
  selectMode
}: {
  messageLength: 'short' | 'long';
  children: RenderProps;
  selectMode: boolean;
}) {
  const theme = useTheme();
  const intl = useIntl();
  const url = useRouter();
  const { languageCodes } = useStaticDataSystem();
  const useStyles = makeStyles(muiTheme => ({
    menuWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem 0.75rem',
      [muiTheme.breakpoints.down('sm')]: {
        padding: '1rem'
      }
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '2.5em',
      left: '0px',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      [muiTheme.breakpoints.down('sm')]: {
        bottom: '0',
        top: 'unset',
        left: 'unset',
        right: '0'
      }
    },
    menuItem: {
      padding: '0',
      backgroundColor: '#fff',
      color: theme.colors.primary,
      '&:hover': {
        [muiTheme.breakpoints.down('sm')]: {
          backgroundColor: theme.colors.primary
        }
      },
      '& a': {
        color: theme.colors.primary,
        '&:hover': {
          [muiTheme.breakpoints.down('sm')]: {
            color: '#fff'
          }
        }
      }
    },
    menuItemSelected: {
      padding: '0',
      backgroundColor: theme.colors.primary,
      '&:hover': {
        backgroundColor: theme.colors.primary,
        color: '#fff'
      },
      '& a': {
        color: '#fff',
        '&:hover': {
          color: '#fff'
        }
      }
    },
    displayItem: {
      padding: '0',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'border-color 0.3s ease',
        [muiTheme.breakpoints.down('sm')]: {
          backgroundColor: theme.colors.primary
        }
      },
      '&.MuiMenuItem-root.MuiButtonBase-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button': {
        [muiTheme.breakpoints.down('sm')]: {
          color: '#fff!important',
          fontSize: '1.625rem'
        }
      }
    },
    link: {
      lineHeight: '1.77em'
    }
  }));
  const classes = useStyles();
  const routeMatch = match(url.asPath);

  const [open, setOpen] = useState(false);
  const { locale, defaultLocale } = intl;
  // find other locale to create the link
  const otherLocale = languageCodes && languageCodes.find(l => l !== locale);

  const queryStringSlug = getQueryString(url.query.slug);
  const foundRoute = routeMatch && routeMatch.route;

  const { data } = useQuery<articlePageQuery, articlePageQueryVariables>(
    ARTICLE_PAGE_QUERY,
    {
      variables: { slug: queryStringSlug || '' },
      skip:
        !foundRoute ||
        !otherLocale ||
        !queryStringSlug ||
        routeMatch.route.name !== 'article'
    }
  );

  if (
    !foundRoute ||
    !otherLocale ||
    (languageCodes && !languageCodes.includes(otherLocale))
  ) {
    return null;
  }

  const nextLocale = otherLocale;

  let slug: string | undefined;
  let slug2: string | undefined;

  if (data?.articlePage?.slugsByLocale) {
    const slugByLocale = data.articlePage.slugsByLocale.find(
      l => l.locale === nextLocale
    );
    if (slugByLocale && slugByLocale.slug) {
      slug = slugByLocale.slug;
    }
  } else {
    const slugs = getSlugs(nextLocale, routeMatch.route.name);
    slug = slugs.slug;
    slug2 = slugs.slug2;
  }

  const toggleOpen = () => {
    setOpen(!open);
  };

  const renderLocaleOptions = () => {
    return (
      <Box className={classes.menu}>
        {languageCodes &&
          languageCodes.map(nextLocale => (
            <MenuItem
              key={nextLocale}
              value={nextLocale}
              className={
                nextLocale === locale
                  ? classes.menuItemSelected
                  : classes.menuItem
              }
            >
              <ChangeLocaleLinkStateless
                routeMatch={routeMatch}
                slug={slug}
                slug2={slug2}
                locale={locale}
                defaultLocale={defaultLocale}
                nextLocale={nextLocale}
                messageLength={messageLength}
              >
                {children}
              </ChangeLocaleLinkStateless>
            </MenuItem>
          ))}
      </Box>
    );
  };

  return selectMode ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box className={classes.menuWrapper} data-testid="change-locale-link">
        <BoxContainer>
          <MenuItem
            className={classes.displayItem}
            onClick={toggleOpen}
            style={{
              color: theme.colors.primary
            }}
          >
            {shortLocaleMessages[locale]}
            <DownIcon
              color={{ xs: '#fff', xl: 'primary' }}
              width="0.65rem"
              marginLeft="0.5rem"
            />
          </MenuItem>
        </BoxContainer>
        {open && renderLocaleOptions()}
      </Box>
    </ClickAwayListener>
  ) : (
    <Box display="flex" flexDirection="column">
      {languageCodes &&
        languageCodes.map(nextLocale => {
          return (
            <Box key={nextLocale} className={classes.link}>
              <ChangeLocaleLinkStateless
                routeMatch={routeMatch}
                slug={slug}
                slug2={slug2}
                locale={locale}
                defaultLocale={defaultLocale}
                nextLocale={nextLocale}
                messageLength={messageLength}
              >
                {children}
              </ChangeLocaleLinkStateless>
            </Box>
          );
        })}
    </Box>
  );
}
