import styled from 'styled-components';
import { Warning } from '@urbaninfrastructure/react-icons';
import { Flex, Text } from '../../components';

const NotificationBar = styled(Flex)`
  background: ${({ theme }) => theme.colors.accent.purple};
  line-height: 1.25rem;
  z-index: 3;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  a {
    color: white;
  }
  p {
    margin-bottom: 0;
  }
`;

function SystemClosedNotification({ closedReason }: { closedReason: string }) {
  return (
    <Flex alignItems="center">
      <Warning size={30} color="white" mr={3} ml={3} />
      <Text as="span" mr={1} typoStyle="xs">
        {closedReason}
      </Text>
    </Flex>
  );
}

export function ClosedNotification({
  closedReason
}: {
  closedReason: string | null;
}) {
  if (!closedReason) {
    return null;
  }
  return (
    <NotificationBar
      justifyContent="space-around"
      alignItems="center"
      role="alert"
      px="xs"
      py="xxs"
    >
      <SystemClosedNotification closedReason={closedReason} />
    </NotificationBar>
  );
}
