import cookie from 'cookie';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { JWT_COOKIE_NAME } from './constants';
import { CurrentUser, currentUserQuery } from '../core-types';
import { CURRENT_USER_QUERY } from './core/useCurrentUser';
import { gql } from '@apollo/client';
import { useStoredVerification } from '../modules/VerifyPhoneNumber/useStoredVerification';

export const INTERCOM_SETTINGS_QUERY = gql`
  query intercomSettingsQuery {
    intercomSettings
  }
`;

function setCookie(jwt: string, maxAge: number, cookieName: string) {
  document.cookie = cookie.serialize(cookieName, jwt, {
    maxAge,
    path: '/'
  });
}

function refetchCurrentUser(client: ApolloClient<object>) {
  return client.query<currentUserQuery>({
    query: CURRENT_USER_QUERY,
    fetchPolicy: 'network-only'
  });
}

export function useOnCodeVerified() {
  const [, , removeStoredVerification] = useStoredVerification();
  const client = useApolloClient();
  return function onCodeVerified({
    jwt,
    user
  }: {
    jwt: string;
    user: CurrentUser;
  }) {
    removeStoredVerification();

    // store token in cookie
    const maxAge = 30 * 24 * 60 * 60; // fixme: calculate this from token `exp`
    setCookie(jwt, maxAge, JWT_COOKIE_NAME);

    let intercomSettings: CORE_JSON = null;

    try {
      const currentUserQueryResult = client.readQuery<currentUserQuery>({
        query: CURRENT_USER_QUERY
      });
      if (currentUserQueryResult) {
        intercomSettings = currentUserQueryResult.intercomSettings;
      }
      // eslint-disable-next-line no-empty
    } catch {}

    client.writeQuery<currentUserQuery>({
      query: CURRENT_USER_QUERY,
      data: {
        currentUser: user,
        intercomSettings
      }
    });
    client.query({
      query: INTERCOM_SETTINGS_QUERY,
      fetchPolicy: 'network-only'
    });
  };
}

export function useAuth(): {
  logOut: () => Promise<void>;
} {
  const client = useApolloClient();
  const [, , removeStoredVerification] = useStoredVerification();

  async function logOut() {
    removeStoredVerification();
    // delete cookie
    setCookie('', -1, JWT_COOKIE_NAME);
    await refetchCurrentUser(client);
  }

  return { logOut };
}
