import styled from 'styled-components';
import { Anchor } from '../../components';

export const NavLink = styled(Anchor)``;

NavLink.defaultProps = {
  display: 'block',
  p: 4,
  px: { xl: 3 },
  textDecoration: 'none',
  color: { _: 'primaryContrast', xl: 'link' } as any,
  textAlign: { _: 'right', xl: 'center' },
  fontSize: { _: '26px', xl: '16px' },
  lineHeight: { xl: 1 }
};
