import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { systemAlertsQuery } from '../../core-types';

export const SYSTEM_ALERTS_QUERY = gql`
  fragment ListSystemAlert on SystemAlert {
    id
    resolvedAt
    dockGroups {
      id
      title
    }
    systemAlertType
    incidentStartTime
    incidentEndTime
    title
    description
    visibility
    slug {
      current
    }
    updates {
      id
      createdAt
      title
      description
    }
  }

  query systemAlertsQuery {
    systemClosureAlerts {
      ...ListSystemAlert
    }

    systemAlerts {
      ...ListSystemAlert
    }
  }
`;

export const useSystemAlertsQuery = (
  options?: QueryHookOptions<systemAlertsQuery>
) => useQuery<systemAlertsQuery>(SYSTEM_ALERTS_QUERY, options);
